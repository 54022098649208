<template>
  <div class="NotificationsSlack" v-if="user.subscription_plan.apps_alerts">
    <div class="top">
      <div class="title">Slack Notifications</div>
    </div>
    <div class="card">
      <div class="card-body">
        <slack-notifications :monitor="monitor"
                             :general="general"
                             @update="$emit('update')" />
      </div>
    </div>
  </div>
  <div v-else>
    <div class="upgrade">
      <UpgradeBadge text="Slack is available only for paid customers."/>
    </div>
  </div>
</template>

<script>
import SlackNotifications from '@/components/Monitor/Notifications/Slack/Slack.vue'
import UpgradeBadge from '@/components/Common/UpgradeBadge.vue'

export default {
  components: {
    UpgradeBadge,
    SlackNotifications
  },

  metaInfo: {
    title: 'Slack Notifications'
  },

  props: {
    monitor: {
      required: false,
      type: Object
    },
    general: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  computed: {
    ...mapState('authentication', ['user'])
  }
}
</script>

<style lang="scss" scoped>
  .NotificationsSlack {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .title {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.5;
        color: #444;
      }
    }
  }
  .upgrade {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    @media (max-width: 767px) {
      margin: 24px 0;
      min-height: unset !important;
    }
  }
</style>
